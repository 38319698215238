<template>
  <div style="margin-top: 20px; margin-bottom: 10px">
    <label>Timer type:</label>
    <a-select style="width: 100%" value="perUserTimer" show-search @change="onChange">
      <a-select-option value="perUserTimer">
        <a-tooltip :mouseLeaveDelay="0" title="Each user will have it's separate timer, that can be started or stopped only by that respective user">
          <div>Unique timer for every user</div>
        </a-tooltip>
      </a-select-option>
      <a-select-option value="globalTimer" disabled="disabled">
        <a-tooltip :mouseLeaveDelay="0" title="One timer for all users, that can be started and stopped by anyone">
          <div>One timer for all users</div>
        </a-tooltip>
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  methods: {
    onChange(selectedValue) {
      if (selectedValue) {
        this.$emit('update:modelValue', {
          timerType: selectedValue,
        });
      } else {
        this.$emit('update:modelValue', null);
      }
    },
  },
};
</script>
