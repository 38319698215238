<template>
  <div class="d-flex flex-row justify-content-start">
    <div class="mx-3">
      <label>Select a Plugin as source data:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedPlugin"
        placeholder="Select a Plugin..."
        option-label-prop="label"
        @change="selectPlugin"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option v-for="plugin in pluginInstalledList" :key="plugin._id" :value="plugin._id" :label="plugin.title">
          {{ plugin.title }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedPlugin" class="mx-3">
      <label>Select an Endpoint to link:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedEndpoint"
        placeholder="Select a Plugin Endpoint..."
        option-label-prop="label"
        @change="selectEndpoint"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option v-for="endpoint in endpointList" :key="endpoint.path" :value="endpoint.path" :label="`${endpoint.path}`">
          {{ endpoint.name }} ({{ endpoint.path }})
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedEndpoint" class="mx-3">
      <div v-for="inputField in inputFieldList" :key="inputField._id">
        <label class="mr-2">Input - {{ inputField.name }} ({{ inputField.type }}):</label>
        <a-switch
          :checked="selectedInputMappedFields[inputField.name]?.['required'] ?? false"
          checked-children="Required"
          un-checked-children="Required"
          @change="value => updateSelectedInputRequired(value, inputField.name)"
        />
        <a-tooltip class="ml-2">
          <template #title>If it is true, it goes to fetch the data from the plugin after the input has a value</template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-select
          class="m-0 mb-1"
          :value="selectedInputMappedFields[inputField.name]?.['toFieldId']"
          placeholder="Select a Field..."
          option-label-prop="label"
          @change="value => updateSelectedInput(value, inputField.name)"
          allowClear
          style="width: 100%"
          show-search
        >
          <a-select-option v-for="field in itemFields" :key="field.id" :value="field.name" :label="`${field.label} (${field.type})`">
            {{ field.label }} ({{ field.type }})
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div v-if="selectedEndpoint" class="mx-3">
      <label>Output:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedOutput"
        placeholder="Select a Plugin Field..."
        option-label-prop="label"
        @change="updateSelectedOutput"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option v-for="field in outputFieldList" :key="field.name" :value="field.name" :label="`${field.name} (${field.type})`">
          {{ field.name }} ({{ field.type }})
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import { PluginsActions } from '@/apps/pluginsManagement/shared/pluginsManagement.store';
import PluginsManagementApi from '@/apps/pluginsManagement/api/pluginsManagement.api';
import { InfoCircleOutlined } from '@ant-design/icons-vue';

export default {
  name: 'PluginFieldManager',
  components: { InfoCircleOutlined },
  props: ['field', 'itemFields'],
  emits: ['update:modelValue'],
  data() {
    return {
      pluginInstalledList: [],
      endpointList: [],
      inputFieldList: [],
      outputFieldList: [],
      selectedPlugin: this.field?.pluginId ?? null,
      selectedPluginPath: this.field?.pluginPath ?? null,
      selectedEndpoint: this.field?.pluginEndpoint ?? null,
      selectedInputMappedFields: this.field?.selectedInputMappedFields ?? {},
      selectedOutput: this.field?.selectedOutput ?? null,
    };
  },
  async created() {
    await this.loadPluginList();
    if (this.selectedPlugin) await this.loadPluginData();
  },
  methods: {
    async loadPluginList() {
      try {
        await PluginsActions.fetchPluginsList();
        this.pluginInstalledList = await PluginsManagementApi.getPluginsByApp();
      } catch (error) {
        console.error('Failed to load plugin list:', error);
      }
    },
    async loadPluginData() {
      const plugin = this.pluginInstalledList.find(item => item._id === this.selectedPlugin);
      if (plugin) {
        const pluginData = await PluginsManagementApi.getPluginStructure(plugin.path);
        this.endpointList = pluginData.endPoints || [];
        if (this.selectedEndpoint) this.loadEndpointData();
      }
    },
    loadEndpointData() {
      const endpoint = this.endpointList.find(item => item.path === this.selectedEndpoint);
      if (endpoint) {
        this.inputFieldList = Object.entries(endpoint.inputFields).map(([key, type], index) => ({ name: key, type, _id: index }));
        this.outputFieldList = Object.entries(endpoint.expectedFields).map(([key, type], index) => ({ name: key, type, _id: index }));
      }
    },
    async selectPlugin(pluginId) {
      const plugin = this.pluginInstalledList.find(item => item._id === pluginId);
      if (plugin) {
        this.selectedPlugin = plugin._id;
        this.selectedPluginPath = plugin.path;
      } else {
        this.selectedPlugin = null;
        this.selectedPluginPath = null;
      }
      this.resetSelections();
      if (pluginId) {
        await this.loadPluginData();
      }
      this.emitUpdate();
    },
    selectEndpoint(endpointPath) {
      this.selectedEndpoint = endpointPath;
      this.resetFieldSelections();
      if (endpointPath) {
        this.loadEndpointData();
      }
      this.emitUpdate();
    },
    updateSelectedInput(value, fieldName) {
      this.selectedInputMappedFields = {
        ...this.selectedInputMappedFields,
        [fieldName]: { required: this.selectedInputMappedFields[fieldName]?.required ?? false, toFieldId: value },
      };
      this.emitUpdate();
    },
    updateSelectedInputRequired(value, fieldName) {
      this.selectedInputMappedFields = {
        ...this.selectedInputMappedFields,
        [fieldName]: { required: value, toFieldId: this.selectedInputMappedFields[fieldName]?.toFieldId ?? null },
      };
      this.emitUpdate();
    },
    updateSelectedOutput(value) {
      this.selectedOutput = value;
      this.emitUpdate();
    },
    resetSelections() {
      this.selectedEndpoint = null;
      this.selectedOutput = null;
      this.selectedInputMappedFields = {};
    },
    resetFieldSelections() {
      this.selectedInputMappedFields = {};
      this.selectedOutput = null;
    },
    emitUpdate() {
      this.$emit('update:modelValue', {
        pluginId: this.selectedPlugin,
        pluginPath: this.selectedPluginPath,
        pluginEndpoint: this.selectedEndpoint,
        selectedInputMappedFields: this.selectedInputMappedFields,
        selectedOutput: this.selectedOutput,
      });
    },
  },
};
</script>
