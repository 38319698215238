<template>
  <div v-if="((blueprint && instance) || (blueprint && addNew) || (blueprint && preview)) && totalFieldsCountFinished">
    <h3 v-if="title">{{ blueprint.name }}</h3>
    <p v-if="blueprint.description && description">
      {{ blueprint.description }}
    </p>
    <a-divider v-if="title || description" />
    <div class="field-row-flex">
      <template v-for="field in filterFields" :key="field._id">
        <div style="width: 100%; flex-basis: auto" :key="'fields-separator-' + field._id" v-if="field?.displayOnNewRow"></div>
        <div v-if="(field?.group ?? '') === ''" style="margin-bottom: 20px" class="mobile-field-div">
          <FieldWidget
            v-if="fieldIdToValue"
            v-model="fieldIdToValue[field._id]"
            :validity="fieldIdToValidity[field._id]"
            :userRolesWhichCanCreate="userRolesWhichCanCreate"
            :userRoles="userRoles"
            :field="field"
            :current-field-values="fieldIdToValue"
            :fields="filterFields"
            :waitForValidity="waitForValidityById[field._id]"
            :addNew="addNew"
            :resetFieldValueOnHide="blueprint?.resetFieldValuesOnHide"
            :firstLoadActive="firstLoadActive"
            @changeWidgetValue="changeWidgetValue"
            @update:model-value="
              value => {
                modifiedFieldId.push(field._id);
              }
            "
          />
        </div>
        <GroupFieldWidget
          :fieldIdToValue="fieldIdToValue"
          :fieldIdToValidity="fieldIdToValidity"
          :userRolesWhichCanCreate="userRolesWhichCanCreate"
          :field="field"
          :fields="fields"
          :fieldIdToValidationFailedArray="fieldIdToValidationFailedArray"
          :firstLoadActive="firstLoadActive"
          @updateGroupFieldIdToValue="updateGroupFieldIdToValue"
        />
      </template>
    </div>
    <SendSMS v-if="showSendSms" :phonesToSend="formPhoneFieldValues" :instanceId="instance?._id" :addNew="addNew"></SendSMS>
    <SendWhatsApp v-if="showSendWhatsApp" :phonesToSend="formPhoneFieldValues" :instanceId="instance?._id" :addNew="addNew"></SendWhatsApp>

    <a-divider v-if="$slots.default" />

    <slot />

    <div style="padding-bottom: 10px" v-if="saveMessage" v-html="saveMessage"></div>
    <div>
      <a-button
        @click="onSubmit"
        :loading="submitLoading"
        :disabled="!formIsValid"
        style="min-width: 120px"
        type="primary"
        size="large"
        v-if="addNew || (instance && instance?._ownerPermissions?.canEdit)"
      >
        {{ editButtonLabel === '' ? 'SAVE' : editButtonLabel }}
      </a-button>
      <!--        <a-button @click="onSubmit" :loading="submitLoading" style="min-width: 120px;" type="primary" size="large" v-if="addNew || (instance && instance?._ownerPermissions?.canEdit)">-->
      <!--          {{ editButtonLabel === "" ? 'SAVE' : editButtonLabel }}-->
      <!--        </a-button>-->
      <a-button
        @click="onDelete"
        :loading="deleteLoading"
        v-if="instance && showDeleteButton && !addNew && instance?._ownerPermissions?.canDelete"
        type="link"
        style="color: #ff4d4f; margin-top: 7px"
      >
        {{ deleteButtonLabel === '' ? 'Delete' : deleteButtonLabel }}
      </a-button>
      <a-button :disabled="!formIsValid" style="min-width: 120px" type="primary" size="large" v-if="preview">
        No action button ! For validation test
      </a-button>
    </div>
  </div>
  <loading-spinner v-else />
</template>

<script>
import { FieldWidget } from '@/apps/dataSystem/components/FieldWidget';

import { BlueprintFormLogicMixin } from '@dataSystem/mixins/BlueprintLogicMixins';
import SendSMS from '@dataSystem/components/SendSMS/SendSMS.vue';
import SendWhatsApp from '@dataSystem/components/SendWhatsApp/SendWhatsApp.vue';
import { isEmpty, slugify } from '@/core/utils/string-manipulation';
import { all, create } from 'mathjs';

import _ from 'lodash';
import GroupFieldWidget from '@dataSystem/components/FieldWidget/GroupFieldWidget.vue';
import { UserGetters } from '@userManagement/user.store';
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';
import { TenantsGetters } from '@/apps/tenants/shared/tenants.store';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';
import axios from 'axios';

export default {
  name: 'FormRenderer',
  mixins: [BlueprintFormLogicMixin],
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: Boolean,
      default: false,
    },
    showSendSms: {
      type: Boolean,
      default: false,
    },
    showSendWhatsApp: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Boolean,
      default: true,
    },
    description: {
      type: Boolean,
      default: true,
    },
    showFields: {
      type: Array,
      default: () => [],
    },
    formSlug: {
      type: String,
      default: null,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    editButtonLabel: {
      type: String,
      default: '',
    },
    editButtonSize: {
      type: String,
      default: 'large',
    },
    editButtonIcon: {
      type: String,
      default: '',
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    cancelButtonLabel: {
      type: String,
      default: '',
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    deleteButtonLabel: {
      type: String,
      default: '',
    },
    deleteLoading: {
      type: Boolean,
      default: false,
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    saveMessage: {
      type: String,
      default: null,
    },
  },
  created() {},
  data() {
    return {
      firstLoadActive: true,
      totalFieldsCountFinished: false,
      totalNrOfFields: null,
      initialNrOfLoadedFields: 0,
      formIsValid: false,
      autoCompleteFields: [],
    };
  },
  watch: {
    firstLoadActive(value) {
      if (value === false) {
        this.pluginAutoComplete();
      }
    },
    fieldIdToValidity: {
      deep: true,
      handler() {
        this.formIsValid = this.isValidationPassed;
      },
    },
    fieldIdToValue: {
      deep: true,
      handler(value) {
        this.calculationExpression(value);
        this.formIsValid = this.isValidationPassed;
      },
    },
    filterFields() {
      this.countFields();
    },
    initialNrOfLoadedFields(value) {
      if (value >= this.totalNrOfFields) {
        this.firstLoadActive = false;

        // after form first load add a random value to form values object to trigger the watcher so that different logic goes into effect
        this.fieldIdToValue._triggerValue = true;
        delete this.fieldIdToValue._triggerValue;
        // end trigger
        // console.log(`Initial form load done! Total # of fields: ${value}`);
      } else {
        // console.log(`loaded fields: ${value}, total: ${this.totalNrOfFields}`);
      }
    },
  },
  provide() {
    return {
      markFieldMounted: this.markFieldComponentMounted,
    };
  },
  components: {
    GroupFieldWidget,
    FieldWidget,
    SendSMS,
    SendWhatsApp,
  },
  computed: {
    user: UserGetters.getUser,
    userRoles: NewTemplatesGetters.getRoles,
    groupFields() {
      return this.fields.filter(item => item.group && item.group !== '' && item.group === this.field.group);
    },
    // currentFieldValues() {
    //   return this.fieldIdToValue;
    // },
    filterFields() {
      const newFields = [];
      for (let i = 0; i < this.fields?.length; i += 1) {
        const field = this.fields[i];
        if (!this.showFields.length) {
          if (this.fieldIdToIsVisible[field.id]) {
            field.displayOnNewRow = true;
            newFields.push(field);
          }
        } else {
          const findField = this.showFields.find(item => item.id === field._id && item.show);
          if (findField && this.fieldIdToIsVisible[field.id]) {
            field.label = findField.label;
            field.customPosition = this.showFields.indexOf(findField);
            field.displayOnNewRow = findField.displayOnNewRow;
            field.defaultFirstValue = findField.defaultFirstValue;
            if (findField?.useDefaultValueFromTemplate) {
              field.useDefaultValueFromTemplate = findField.useDefaultValueFromTemplate;
              field.defaultTemplateValues = findField.defaultTemplateValues;
            }
            if (field?.logic?.calculation?.expression) {
              // console.log(field?.logic?.calculation?.expression);
            }

            newFields.push(field);
          }
        }
      }
      return newFields.sort((a, b) => a.customPosition - b.customPosition);
    },
    formPhoneFieldValues() {
      const phoneValues = [];
      if (this.showSendSms) {
        this.filterFields.forEach(f => {
          if (f.widget.type === 'phone_number') {
            phoneValues.push(this.fieldIdToValue[f._id]);
          }
        });
      }
      return phoneValues;
    },
  },
  methods: {
    changeWidgetValue(data) {
      if (this.firstLoadActive === false) {
        if (this.autoCompleteFields.includes(data.fieldId)) {
          this.pluginAutoComplete();
        }
      }
    },
    async pluginAutoComplete() {
      const autoComplete = this.object.view.listFields.filter(item => item.autoComplete?.isEnabled);

      const payloadPlugins = {};

      // Getting the input values for Plugin Auto Complete.
      autoComplete
        .filter(field => field.autoComplete.type === 'plugin')
        .map(async field => {
          Object.entries(field.autoComplete?.pluginField?.selectedInputMappedFields).forEach(([index, item]) => {
            if (item.toFieldId) {
              this.autoCompleteFields.push(item.toFieldId);
              if (!(field.autoComplete?.pluginField?.pluginId in payloadPlugins)) {
                payloadPlugins[field.autoComplete?.pluginField?.pluginId] = {
                  endpoint: field.autoComplete?.pluginField?.pluginEndpoint,
                  input: {},
                  output: {},
                  mapper: {},
                };
              }

              payloadPlugins[field.autoComplete?.pluginField?.pluginId] = {
                ...payloadPlugins[field.autoComplete?.pluginField?.pluginId],
                isCallabe: item.required && this.fieldIdToValue[item.toFieldId] !== null && this.fieldIdToValue[item.toFieldId] !== '',
              };

              if (payloadPlugins[field.autoComplete?.pluginField?.pluginId].isCallabe) {
                payloadPlugins[field.autoComplete?.pluginField?.pluginId].input[index] = this.fieldIdToValue[item.toFieldId];
                payloadPlugins[field.autoComplete?.pluginField?.pluginId].mapper[field.id] = field.autoComplete?.pluginField?.selectedOutput;
              }
            }
          });
        });

      // console.log('After plugin initialization', payloadPlugins);

      // The following code is used to fetch the response of the plugin call.
      // eslint-disable-next-line no-restricted-syntax
      for (const [pluginId, payload] of Object.entries(payloadPlugins)) {
        if (payload.isCallabe) {
          // eslint-disable-next-line no-await-in-loop
          const plugin = await pluginsManagementApi.getPluginById(pluginId);
          const data = TenantsGetters.getTenantAppSubtenant();

          console.log(data);

          try {
            const findEndPoint = plugin.endPoints.find(item => item.path === payload.endpoint);
            if (findEndPoint) {
              if (findEndPoint?.method === 'POST') {
                // eslint-disable-next-line no-await-in-loop
                payloadPlugins[pluginId].output = await axios
                  .post(`/plugins/${data.tenant.slug}/${data.app.slug}/${plugin.path}${findEndPoint.path}`, {
                    inputParams: payload.input,
                    subtenantSlug: data.subtenant.slug,
                    userId: this.user._id,
                  })
                  .then(response => response.data);
              } else {
                // eslint-disable-next-line no-await-in-loop
                payloadPlugins[pluginId].output = await axios
                  .get(`/plugins/${data.tenant.slug}/${data.app.slug}/${plugin.path}${findEndPoint.path}`)
                  .then(response => response.data);
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }

      // console.log('After plugin output', payloadPlugins);

      // eslint-disable-next-line no-restricted-syntax,no-unused-vars
      for (const [pluginId, payload] of Object.entries(payloadPlugins)) {
        if (payload.isCallabe) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [fieldId, pluginFieldId] of Object.entries(payload.mapper)) {
            this.waitForValidityById = { ...(this.waitForValidityById ?? {}), [fieldId]: true };

            this.fieldIdToValue[fieldId] = payload?.output?.[pluginFieldId];

            this.waitForValidityById = { ...(this.waitForValidityById ?? {}), [fieldId]: false };
          }
        }
      }
    },
    markFieldComponentMounted() {
      this.initialNrOfLoadedFields += 1;
    },
    countFields() {
      if (this.filterFields.length && !this.totalFieldsCountFinished) {
        this.totalNrOfFields = this.filterFields.length;
        this.totalFieldsCountFinished = true;
      }
    },
    updateGroupFieldIdToValue(data) {
      if (data.groupFieldId) {
        if (this.modifiedFieldId.indexOf(data.groupFieldId) === -1) {
          this.modifiedFieldId.push(data.groupFieldId);
        }
        this.fieldIdToValue[data.groupFieldId] = data.rawValue;
      }
    },
    async calculationExpression(instance) {
      const data = await this.getCalculationData(instance);
      if (Object.keys(data).length) {
        this.math = create(all, {});
        this.math.import({ ...data.vars });

        Object.values(this.fieldsById).forEach(field => {
          const { calculation } = field.logic;
          if (calculation.isEnabled) {
            if (!isEmpty(calculation.expression)) {
              try {
                const parsedExpression = this.math.parse(calculation.expression);
                const fieldVariables = [];
                parsedExpression.traverse(function (node, path, parent) {
                  if (node.type === 'SymbolNode' && parent.type !== 'FunctionNode') {
                    fieldVariables.push(node.name);
                  }
                });

                // if at least one of the expression fields has a value, we compute the final value
                if (
                  fieldVariables.some(fieldVar => {
                    const fieldId = data.fieldIds[fieldVar];
                    return !!this.fieldIdToValue[fieldId];
                  })
                ) {
                  const result = this.math.evaluate(calculation.expression);
                  const fieldStructure = this.filterFields.find(f => f._id === field._id)?.structure;
                  this.fieldIdToValue[`${field._id}`] = _.round(result, fieldStructure?.options?.decimalPlaces ?? 0);
                }
                // else we set it to null to not enter a 0 value in the db
                else {
                  this.fieldIdToValue[field._id] = null;
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        });
      }
    },

    async getCalculationData(instance) {
      const data = {
        vars: {},
        fieldIds: {},
      };
      const filteredFields = Object.values(this.fieldsById).filter(
        field => (field?.structure?.elementStructure?.type ?? field?.structure?.type) === 'number'
      );
      for (let i = 0; i < filteredFields?.length; i += 1) {
        const item = filteredFields[i];
        if (Array.isArray(instance[item._id]) && this.filterFields.some(field => field._id === item._id)) {
          data.vars[slugify(item.label)] = (instance[item._id][0] ? instance[item._id] : []).reduce((partialSum, a) => partialSum + a, 0);

          if (instance[item._id][0]) {
            data.vars[`${slugify(item.label)}_count`] = instance[item._id].length;
            data.vars[`${slugify(item.label)}_exists`] = 1;

            data.fieldIds[`${slugify(item.label)}_count`] = item._id;
            data.fieldIds[`${slugify(item.label)}_exists`] = item._id;
          } else {
            data.vars[`${slugify(item.label)}_count`] = 1;
            data.vars[`${slugify(item.label)}_exists`] = 0;

            data.fieldIds[`${slugify(item.label)}_count`] = item._id;
            data.fieldIds[`${slugify(item.label)}_exists`] = item._id;
          }
        } else if (this.filterFields.some(field => field._id === item._id)) {
          data.vars[slugify(item.label)] = isEmpty(instance[item._id])
            ? 0
            : _.round(parseFloat(instance[item._id]), item?.structure?.options?.decimalPlaces ?? 0); // round to calculate decimals , bug detected when decimals changes
          data.vars[`${slugify(item.label)}_exists`] = isEmpty(instance[item._id]) ? 0 : 1;

          data.fieldIds[slugify(item.label)] = item._id;
          data.fieldIds[`${slugify(item.label)}_exists`] = item._id;
        } else {
          data.vars[slugify(item.label)] = 0;
          data.vars[`${slugify(item.label)}_exists`] = 0;

          data.fieldIds[slugify(item.label)] = item._id;
          data.fieldIds[`${slugify(item.label)}_exists`] = item._id;
        }
      }
      return data;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .mobile-field-div {
    width: 100%;
    display: block;
    flex: 100%;
  }
}
</style>
