<template>
  <div>
    Tip element: {{ localTemplate.object?.view?.type }}<br />
    Blueprint: {{ selectedBlueprint?.name }} (id: {{ selectedBlueprint?._id }})<br />

    <div class="elementButtons">
      <a-tooltip title="Console log element"
        ><a-button size="small" @click="consoleConfig"><OrderedListOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Delete element"
        ><a-button @click="deleteElement" size="small"><DeleteOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Configurare setari element"
        ><a-button @click="modalSetariElementVisible = true" size="small"><SettingOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Configurare fields element"
        ><a-button @click="modalConfigFieldsVisible = true" size="small"><UnorderedListOutlined /></a-button
      ></a-tooltip>
      <a-tooltip title="Configurare filtre element" v-if="localTemplate.object?.view?.type === 'chart'"
        ><a-button size="small" @click="modalConfigObjectFilters = true"><FilterOutlined /></a-button
      ></a-tooltip>
    </div>

    <!-- MODALS-->
    <a-modal v-model:open="showFileExportModal" :footer="null" title="File export" :width="1000" @cancel="showFileExportModal = false">
      <FileExporting :template="localTemplate" :mainTemplate="mainTemplate"></FileExporting>
    </a-modal>

    <ElementSettings
      v-if="modalSetariElementVisible === true"
      :selectedBlueprint="selectedBlueprint"
      :template="localTemplate"
      :templates="templates"
      :blueprints="blueprints"
      v-model:modalSetariElementVisible="modalSetariElementVisible"
    ></ElementSettings>
    <ElementFields
      v-if="modalConfigFieldsVisible === true"
      :blueprints="blueprints"
      :selectedBlueprint="selectedBlueprint"
      :template="localTemplate"
      :templates="templates"
      :mainBlueprintId="mainBlueprintId"
      v-model:modalConfigFieldsVisible="modalConfigFieldsVisible"
    ></ElementFields>

    <ElementFilters
      v-if="modalConfigObjectFilters === true"
      :mainBlueprintId="mainBlueprintId"
      :blueprints="blueprints"
      :selectedBlueprint="selectedBlueprint"
      :template="localTemplate"
      v-model:modalConfigObjectFilters="modalConfigObjectFilters"
    ></ElementFilters>
  </div>
</template>

<script>
import moment from 'moment/moment';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { DeleteOutlined, FilterOutlined, OrderedListOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import ElementFilters from '@/apps/templateManagement/views/Builder/components/ElementFilters.vue';
import ElementFields from '@/apps/templateManagement/views/Builder/components/ElementFields.vue';
import ElementSettings from './ElementSettings.vue';

import FileExporting from '../fileExporting/FileExporting.vue';

export default {
  name: 'ElementConfiguration',
  props: ['mainBlueprintId', 'selectedBlueprint', 'mainTemplate', 'template', 'templates', 'blueprints'],
  emits: ['deleteElement'],
  components: {
    FilterOutlined,
    UnorderedListOutlined,
    ElementFields,
    ElementFilters,
    ElementSettings,
    FileExporting,
    DeleteOutlined,
    OrderedListOutlined,
    SettingOutlined,
  },

  created() {
    this.addMissingObjectProperties();
    this.addMissingFieldFormattingObjects();
  },
  mounted() {
    this.populateMissingFields();
    this.addBpidBpnameForFields();
    this.localTemplate = this.template;
  },
  data() {
    return {
      modalSetariElementVisible: false,
      modalConfigFieldsVisible: false,
      modalConfigOrderVisible: false,

      showFileExportModal: false,

      modalConfigObjectFilters: false,

      fromBlueprintId: null,
      fromFieldId: null,

      operation: null,
      localTemplate: this.template ?? null,
    };
  },
  computed: {
    moment() {
      return moment;
    },
  },
  methods: {
    consoleConfig() {
      console.log(this.localTemplate);
    },

    addBpidBpnameForFields() {
      this.localTemplate.object.view.listFields.forEach(templateField => {
        if (!templateField.blueprintId || !templateField.blueprintName) {
          const bpField = this.selectedBlueprint.fields.find(f => f.id === templateField.id);

          if (bpField?.structure?.type === 'reference') {
            if (!templateField.blueprintId) {
              templateField.blueprintId = bpField.structure.ruleset.blueprintId;
            }
            if (!templateField.blueprintName) {
              templateField.blueprintName = this.blueprints.find(bp => bp.id === bpField.structure.ruleset.blueprintId)?.name ?? '';
            }
          }
        }
      });

      this.localTemplate.object.view.itemFields.forEach(templateField => {
        if (!templateField.blueprintId || !templateField.blueprintName) {
          const bpField = this.selectedBlueprint.fields.find(f => f.id === templateField.id);

          if (bpField?.structure?.type === 'reference') {
            if (!templateField.blueprintId) {
              templateField.blueprintId = bpField.structure.ruleset.blueprintId;
            }
            if (!templateField.blueprintName) {
              templateField.blueprintName = this.blueprints.find(bp => bp.id === bpField.structure.ruleset.blueprintId)?.name ?? '';
            }
          }
        }
      });
    },
    populateMissingFields() {
      this.selectedBlueprint.fields.forEach(blueprintField => {
        const tmplFieldList = this.localTemplate.object.view.listFields.find(templateField => templateField.id === blueprintField.id);
        if (tmplFieldList) {
          if (!tmplFieldList?.autoComplete) {
            tmplFieldList.autoComplete = {
              isEnabled: false,
              triggerField: null,
              dataField: null,
            };
          }
        }
        let typeDetails =
          blueprintField.structure?.type === 'array'
            ? `${blueprintField.structure.type}[${blueprintField?.structure?.elementStructure?.type}]`
            : (blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type);

        if (tmplFieldList) {
          // update types for old templates
          tmplFieldList.type = blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type;
          tmplFieldList.typeDetails = typeDetails;
          tmplFieldList.inputType = blueprintField.input.type;
          tmplFieldList.widgetType = blueprintField.widget.type;
        }

        if (!tmplFieldList) {
          const blueprintId =
            blueprintField.structure?.elementStructure?.type === 'reference'
              ? (blueprintField.structure.elementStructure.ruleset?.blueprintId ?? null)
              : (blueprintField?.structure?.ruleset?.blueprintId ?? null);

          typeDetails =
            blueprintField.structure?.type === 'array'
              ? `${blueprintField.structure.type}[${blueprintField?.structure?.elementStructure?.type}]`
              : (blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type);
          if (tmplFieldList) {
            // update types for old templates
            tmplFieldList.widgetType = blueprintField.widget.type;
          }
          this.localTemplate.object.view.listFields.push({
            id: blueprintField.id,
            label: blueprintField.label,
            show: false,
            order: false,
            searchable: false,
            formatting: {
              toUppercase: false,
            },
            filter: false,
            filterValues: [],
            linkToReferenceInstance: false,
            blueprintId,
            blueprintName: blueprintId ? this.blueprints.find(bp => bp.id === blueprintId)?.name : '',

            type: blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type,
            typeDetails,
            widgetType: blueprintField.widget.type,
            inputType: blueprintField.input.type,
            deletable: false,
            autoComplete: {
              isEnabled: false,
              fromCondition: false,
              triggerField: null,
              dataField: null,
            },
          });
        } else if (!tmplFieldList.label || tmplFieldList.label === '') {
          tmplFieldList.label = blueprintField.label;
        }

        const tmplFieldItem = this.localTemplate.object.view.itemFields.find(templateField => templateField.id === blueprintField.id);
        // update types for old templates
        if (tmplFieldItem) {
          tmplFieldItem.type = blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type;
          tmplFieldItem.typeDetails = typeDetails;
          tmplFieldItem.inputType = blueprintField.input.type;
          tmplFieldItem.widgetType = blueprintField.widget.type;
          tmplFieldItem.currentBpLabel = blueprintField.label;
        }

        if (!tmplFieldItem) {
          const blueprintId =
            blueprintField.structure?.elementStructure?.type === 'reference'
              ? (blueprintField.structure.elementStructure?.ruleset?.blueprintId ?? null)
              : (blueprintField?.structure?.ruleset?.blueprintId ?? null);

          typeDetails =
            blueprintField.structure?.type === 'array'
              ? `${blueprintField.structure.type}[${blueprintField?.structure?.elementStructure?.type}]`
              : (blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type);

          if (tmplFieldItem) {
            // update types for old templates
            tmplFieldItem.widgetType = blueprintField.widget.type;
          }
          this.localTemplate.object.view.itemFields.push({
            id: blueprintField.id,
            label: blueprintField.label,
            currentBpLabel: blueprintField.label,
            show: false,
            displayOnNewRow: true,
            linkToReferenceInstance: false,
            blueprintId,
            blueprintName: blueprintId ? this.blueprints.find(bp => bp.id === blueprintId)?.name : '',

            type: blueprintField?.structure?.elementStructure?.type ?? blueprintField?.structure?.type,
            typeDetails,
            widgetType: blueprintField.widget.type,
            inputType: blueprintField.input.type,
            deletable: false,
          });
        } else if (!tmplFieldItem.label || tmplFieldItem.label === '') {
          tmplFieldItem.label = blueprintField.label;
        }
      });

      this.removeDeletedFieldsFromTemplate();
      this.checkListFieldsDefaultColumns();
    },
    // removes deleted blueprint fields from the template
    removeDeletedFieldsFromTemplate() {
      this.localTemplate.object.view.listFields.forEach(lf => {
        if (!this.selectedBlueprint.fields.some(f => f.id === lf.id) && lf.id !== 'owner.fullName' && !lf.id.startsWith('_') && lf.id !== 'action') {
          this.localTemplate.object.view.listFields = this.localTemplate.object.view.listFields.filter(field => field.id !== lf.id);
          this.localTemplate.object.view.itemFields = this.localTemplate.object.view.itemFields.filter(field => field.id !== lf.id);
        }
      });
    },
    // checks for actions column and adds it if it's missing

    checkListFieldsDefaultColumns() {
      // owner (user that created the instance) column
      if (!this.localTemplate.object.view.listFields.some(f => f.id === 'owner.fullName')) {
        this.localTemplate.object.view.listFields.push({
          id: 'owner.fullName',
          label: 'User',
          show: true,
        });
      }

      // added at column
      if (!this.localTemplate.object.view.listFields.some(f => f.id === '_created_at')) {
        this.localTemplate.object.view.listFields.push({
          id: '_created_at',
          label: 'Adaugat',
          show: true,
        });
      }

      // actions column
      if (!this.localTemplate.object.view.listFields.some(f => f.id === 'action')) {
        this.localTemplate.object.view.listFields.push({
          id: 'action',
          label: 'Action',
          show: true,
          delete: true,
        });
      }
    },
    addMissingObjectProperties() {
      if (!this.localTemplate.object.view?.contentHtmlSettings) {
        this.localTemplate.object.view.contentHtmlSettings = {};
        this.localTemplate.object.view.contentHtmlSettings.idToLabel = false;
        this.localTemplate.object.view.contentHtmlSettings.fieldReferenceData = false;
        this.localTemplate.object.view.contentHtmlSettings.excludeFieldMultipleReferenceData = false;
      }
    },
    addMissingFieldFormattingObjects() {
      this.localTemplate.object.view.listFields.forEach(listField => {
        if (!listField?.formatting) {
          listField.formatting = {
            toUppercase: false,
          };
        }
      });
    },
    deleteElement() {
      this.$emit('deleteElement');
    },
  },
  watch: {
    'localTemplate.object.view.filters': {
      handler() {
        if (this.localTemplate.object.view.filters[0] && this.localTemplate.object.view.filters[0].operation !== 'and') {
          this.localTemplate.object.view.filters[0].operation = 'and';
        }
        TemplatesActions.setTemplate(this.localTemplate);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.elementButtons {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
.elementButtons button {
  margin: 0 3px 3px 0;
}
</style>
