<template>
  <div v-if="addNew">Timer available on edit!</div>
  <div v-else>
    <a-input-group compact>
      <a-input title="Elapsed time" :value="displayTime" style="width: 130px; text-align: center" readonly="readonly" />
      <a-button
        :class="!isTimerStarted ? 'ant-btn-custom-green' : ''"
        :loading="loading"
        type="primary"
        :danger="isTimerStarted"
        :title="isTimerStarted ? 'STOP' : 'START'"
        @click="modalConfirmStartStopVisible = true"
      >
        <template #icon><FieldTimeOutlined /></template>
        {{ isTimerStarted ? 'STOP' : 'START' }} TIMER
      </a-button>
    </a-input-group>
    <div style="margin-top: 5px; padding: 10px; border-radius: 8px; border: 1px solid #d9d9d9">
      Latest timer actions:
      <div v-for="item in latestTwoActions" :key="item.timestamp">
        <div v-if="timerType === 'perUserTimer'">
          {{ item.action === 'start' ? 'Started' : 'Stopped' }} at <i>{{ timestampToDate(item.timestamp) }}</i
          >.
        </div>
        <div v-else>
          <strong>{{ item.userId === user._id ? 'You' : getUserFullNameFromId(item.userId) }}</strong>
          {{ item.action === 'start' ? 'started' : 'stopped' }} the timer at <i>{{ timestampToDate(item.timestamp) }}</i
          >.
        </div>
      </div>
      <div v-if="timerActions?.length > 2">
        ...<br />
        <a-button size="small" type="primary" @click="modalVisible = true">See full timer history</a-button>
      </div>
    </div>

    <a-modal v-model:open="modalVisible" :footer="null">
      <div>
        <div v-for="(item, index) in timerActions" :key="item.timestamp">
          <div v-if="timerType === 'perUserTimer'">
            {{ index + 1 }}. {{ item.action === 'start' ? 'Started' : 'Stopped' }} at <i>{{ timestampToDate(item.timestamp) }}</i
            >.
          </div>
          <div v-else>
            {{ index + 1 }}. <strong>{{ item.userId === user._id ? 'You' : getUserFullNameFromId(item.userId) }}</strong>
            {{ item.action === 'start' ? 'started' : 'stopped' }} the timer at <i>{{ timestampToDate(item.timestamp) }}</i
            >.
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model:open="modalConfirmStartStopVisible"
      @ok="
        startStopTimer();
        modalConfirmStartStopVisible = false;
      "
    >
      {{ isTimerStarted ? 'STOP' : 'START' }} TIMER?
    </a-modal>
  </div>
</template>

<script>
// import { isValidJson } from '@/core/utils/string-manipulation';
import { message } from 'ant-design-vue';
import { FieldTimeOutlined } from '@ant-design/icons-vue';
import { UserGetters, UserActions } from '@/apps/userManagement/user.store';
import InputMixin from './InputMixin';
import TimerHelper from './Timer/timerHelper';

export default {
  mixins: [InputMixin],
  data() {
    return {
      loading: false,
      now: Date.now(),
      intervalId: null,
      modalVisible: false,
      modalConfirmStartStopVisible: false,
    };
  },
  components: {
    FieldTimeOutlined,
  },
  async mounted() {
    // force array value if not
    if (!Array.isArray(this.value)) {
      this.inputValue = [];
    }
    // start counter if timer is on
    if (this.isTimerStarted) {
      this.intervalId = setInterval(() => {
        this.now = Date.now();
      }, 1000);
    }
    await this.fetchMissingUserFullNames();
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    user: UserGetters.getUser,
    userFullNameByIdList: UserGetters.getUserNamesByIdList,
    timerType() {
      return TimerHelper.getTimerType(this.field);
    },
    timerActions() {
      return TimerHelper.getTimerActions(this.inputValue, this.timerType, this.user._id);
    },
    latestTwoActions() {
      return this.timerActions.slice(-2);
    },
    isTimerStarted() {
      return TimerHelper.checkIfTimerIsStarted(this.timerActions);
    },
    totalElapsed() {
      let total = 0;
      let currentStart = null;
      this.timerActions.forEach(record => {
        if (record.action === 'start') {
          currentStart = record.timestamp;
        } else if (record.action === 'stop' && currentStart !== null) {
          total += record.timestamp - currentStart;
          currentStart = null;
        }
      });
      if (this.isTimerStarted && currentStart !== null) {
        total += this.now - currentStart;
      }
      return total;
    },
    displayTime() {
      const totalSeconds = Math.floor(this.totalElapsed / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
  },
  watch: {
    value() {},
    inputValue: {
      handler(val) {
        if (val === null) {
          this.inputValue = [];
        }
      },
    },
  },
  methods: {
    async fetchMissingUserFullNames() {
      // check if any user full name is missing and fetch it
      if (this.inputValue?.length) {
        const missingUserFullNames = [];
        this.inputValue.forEach(item => {
          if (!this.userFullNameByIdList.find(u => u.userId === item.userId) && !missingUserFullNames.find(u => u === item.userId)) {
            missingUserFullNames.push(item.userId);
          }
        });
        if (missingUserFullNames.length) {
          await UserActions.fetchUserFullNameByIdList(missingUserFullNames);
        }
      }
    },
    async startStopTimer() {
      if (Array.isArray(this.inputValue)) {
        this.loading = true;

        const newRecord = {
          userId: this.user._id,
          timestamp: Date.now(),
          action: this.isTimerStarted ? 'stop' : 'start',
        };
        const newTimerData = [...this.inputValue, newRecord];
        if (this.isTimerStarted) {
          // Starting the timer
          this.now = newRecord.timestamp;
          this.intervalId = setInterval(() => {
            this.now = Date.now();
          }, 1000);
        } else {
          // Stopping the timer
          clearInterval(this.intervalId);
          this.intervalId = null;
        }

        // save data to DB only if not in previewMode (only when we have an instance open and not when we are configuring the blueprint)
        if (!this.previewMode) {
          const currentInstanceId = this.currentFieldValues?._id;
          const currentBlueprintId = this.currentFieldValues?._blueprintId;
          const currentFieldId = this.field?._id;
          if (currentInstanceId && currentBlueprintId && currentFieldId) {
            const updateTimerFieldResponse = await TimerHelper.submitNewTimerRecord(
              currentBlueprintId,
              currentInstanceId,
              currentFieldId,
              newTimerData
            );

            if (updateTimerFieldResponse.status === 'ok') {
              // if timer started or stopped by current user
              if (updateTimerFieldResponse.data.status === 'ok') {
                this.inputValue = newTimerData;
                message.success({
                  content: () => `Timpul a fost ${newRecord.action === 'start' ? 'PORNIT' : 'OPRIT'}!`,
                  style: {
                    marginTop: '20vh',
                  },
                });
              }
              // if timer started or stopped by a different user (only used for global timers)
              else if (updateTimerFieldResponse.data.status === 'nok') {
                if (Array.isArray(updateTimerFieldResponse.data.newTimerData)) {
                  this.inputValue = updateTimerFieldResponse.data.newTimerData;
                  await this.fetchMissingUserFullNames();
                }
                message.error({
                  content: () => updateTimerFieldResponse.data.message,
                  duration: 10,
                  style: {
                    marginTop: '20vh',
                  },
                });
              }
            }
            // if there was a backend error
            else if (updateTimerFieldResponse.status === 'internal_error') {
              message.error({
                content: () => 'Internal server error! Please try again later.',
                style: {
                  marginTop: '20vh',
                },
              });
            }
          }
        }

        this.loading = false;
      }
    },
    pad(num) {
      return num.toString().padStart(2, '0');
    },
    timestampToDate(timestamp) {
      const dateObj = new Date(timestamp);

      // Format time: hours, minutes, seconds with two-digit padding
      const hours = dateObj.getHours().toString().padStart(2, '0');
      const minutes = dateObj.getMinutes().toString().padStart(2, '0');
      const seconds = dateObj.getSeconds().toString().padStart(2, '0');
      const time = `${hours}:${minutes}:${seconds}`;

      // Format date: day, month, year with two-digit day and month
      const day = dateObj.getDate().toString().padStart(2, '0');
      const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // months are zero-indexed
      const year = dateObj.getFullYear();
      const date = `${day}.${month}.${year}`;

      return `${time}, ${date}`;
    },
    getUserFullNameFromId(userId) {
      const userFullName = this.userFullNameByIdList.find(u => u.userId === userId);
      return userFullName ? userFullName.userFullName : 'Unknown';
    },
  },
};
</script>

<style></style>
