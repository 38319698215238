import axios from 'axios';
import { SlugGetters } from '@/slug.store';

const { getTenantSlug, getAppSlug, getAppUrl } = SlugGetters;

const getAll = async () => {
  try {
    const response = await axios.get(`/plugins`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};
const getPluginStructure = async pluginPath => {
  try {
    const path = `/plugins/${getTenantSlug()}/${getAppSlug()}/${pluginPath}/conf`;
    const response = await axios.get(path);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getPluginsByApp = async () => {
  try {
    const path = `${getAppUrl()}/plugin-management/all`;
    const response = await axios.get(path);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};
const getPluginById = async pluginId => {
  try {
    const path = `${getAppUrl()}/plugin-management/${pluginId}/configuration`;
    const response = await axios.get(path);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const getPluginByPath = async pluginPath => {
  try {
    const path = `${getAppUrl()}/plugin-management/${pluginPath}/all`;
    const response = await axios.get(path);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const postPlugin = async ({ plugin }) => {
  try {
    const path = `${getAppUrl()}/plugin-management/add`;
    const response = await axios.post(path, plugin);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

const patchPlugin = async ({ plugin }) => {
  try {
    const path = `${getAppUrl()}/plugin-management/update`;
    const response = await axios.patch(path, plugin);
    return response.data;
  } catch (error) {
    return {
      error: true,
      response: error.response,
    };
  }
};

export default {
  getAll,
  getPluginById,
  getPluginsByApp,
  getPluginByPath,
  postPlugin,
  patchPlugin,
  getPluginStructure,
};
