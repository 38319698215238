<template>
  <a-modal v-model:open="modalView" title="Configurare setari element" :width="1000" @cancel="close">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="Element settings">
        <div>
          <a-row>
            <a-col :span="6" style="padding-right: 20px">
              <a-checkbox v-model:checked="localTemplate.object.view.default.showTitle"> Show title </a-checkbox>
              <div></div>
              Custom title<br />
              <a-input v-model:value="localTemplate.object.view.default.customTitle" :placeholder="selectedBlueprint.name" />
            </a-col>
            <a-col :span="6">
              <a-row>
                <a-checkbox v-model:checked="localTemplate.object.view.default.convertOwnerIdToOwnerDetails">
                  convertOwnerIdToOwnerDetails
                </a-checkbox>
              </a-row>
              <a-row>
                <a-checkbox v-model:checked="localTemplate.object.view.default.showSearchTable"> showSearchTable </a-checkbox>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <a-tabs default-active-key="2" style="margin-top: 20px">
          <a-tab-pane key="3" tab="Cards settings">
            <a-row class="mb-4">
              <a-col :span="6" style="padding-right: 20px">
                <a-checkbox v-model:checked="localTemplate.object.view.default.showAddNewButton"> showAddNewButton </a-checkbox>
                <div></div>
                addNewButtonLabel<br />
                <a-input v-model:value="localTemplate.object.view.default.addNewButtonLabel" placeholder="New button label" />
              </a-col>

              <a-col :span="6" style="padding-right: 20px">
                <a-checkbox v-model:checked="localTemplate.object.view.default.showEditButton"> showEditButton </a-checkbox>
                <div></div>
                editButtonLabel<br />
                <a-input v-model:value="localTemplate.object.view.default.editButtonLabel" placeholder="Edit button label" />
              </a-col>

              <a-col :span="6" style="padding-right: 20px">
                <a-checkbox v-model:checked="localTemplate.object.view.default.showDeleteButton"> showDeleteButton </a-checkbox>
                <div></div>
                deleteButtonLabel<br />
                <a-input v-model:value="localTemplate.object.view.default.deleteButtonLabel" placeholder="Delete button label" />
              </a-col>
              <a-col :span="6" style="padding-right: 20px">
                <a-checkbox v-model:checked="localTemplate.object.view.default.showViewButton"> showViewButton </a-checkbox>
                <div></div>
                deleteViewLabel<br />
                <a-input v-model:value="localTemplate.object.view.default.viewLabel" placeholder="View button label" />
              </a-col>
            </a-row>
            <a-row class="mb-4">
              <a-col :span="6" style="padding-right: 20px">
                cardsTitleFiledId:<br />
                <a-select
                  v-model:value="localTemplate.object.view.default.kanban.cardsTitleFiledId"
                  :options="selectedBlueprintFields()"
                  :filter-option="filterOption"
                  showSearch
                  style="width: 100%"
                ></a-select>
              </a-col>
              <a-col :span="6" style="padding-right: 20px">
                cardsOrderFiledId:<br />
                <a-select
                  v-model:value="localTemplate.object.view.default.kanban.cardsOrderFiledId"
                  :options="selectedBlueprintFields('number')"
                  :filter-option="filterOption"
                  showSearch
                  style="width: 100%"
                ></a-select>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="4" tab="Columns settings">
            <a-row class="mb-4">
              <a-col :span="6" style="padding-right: 20px">
                columnTitleFieldId:<br />
                <a-select
                  v-model:value="localTemplate.object.view.default.kanban.columnTitleFieldId"
                  :options="referenceBlueprintFields()"
                  :filter-option="filterOption"
                  showSearch
                  style="width: 100%"
                  placeholder="Column title field"
                ></a-select>
              </a-col>
              <a-col :span="6" style="padding-right: 20px">
                columnOrderFieldId:<br />
                <a-select
                  v-model:value="localTemplate.object.view.default.kanban.columnOrderFieldId"
                  :options="referenceBlueprintFields('number')"
                  :filter-option="filterOption"
                  showSearch
                  style="width: 100%"
                  placeholder="Column number order field"
                ></a-select>
              </a-col>
              <a-col :span="6" style="padding-right: 20px">
                columnsWidth:<br />
                <a-input v-model:value="localTemplate.object.view.default.kanban.columnsWidth" placeholder="columns width" />
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="5" tab="Columns totals">
            <div class="row mb-3" v-for="(body, index) in localTemplate.object.view.default.kanban.columsTotals" :key="'body_' + index">
              <div class="col-3">
                <a-input v-model:value="body.label" placeholder="Label" />
              </div>
              <div class="col-4">
                <a-textarea v-model:value="body.calculation" placeholder="Calculation"></a-textarea>
              </div>
              <div class="col-1">
                <a-input v-model:value="body.unit" placeholder="Unit" />
              </div>
              <div class="col-1">
                <a-checkbox v-model:checked="body.numberFormat">Number format</a-checkbox>
              </div>
              <div class="col-2">
                <a-input v-model:value="body.decimalPlaces" placeholder="Decimal places" />
              </div>
              <div class="col-1">
                <a-button @click="removeColumnsTotals(body)"><DeleteOutlined /></a-button>
              </div>
            </div>
            <div class="calculations">
              Fields available for calculation:
              <a-button size="small" v-for="tag of kanbanValues()" :key="tag" @click="copyField(tag.value)" style="margin: 0 5px 5px 0">
                {{ tag.label }} <CopyOutlined />
              </a-button>
              <help-icon-popover
                title="Calculations tutorial"
                content="All calculations available: SUM, AVERAGE, COUNT, MIN, MAX <br /> 
                1. SUM(field_1) OR SUM(field_2) and so on <br />
                2. AVERAGE(field_1) OR AVERAGE(field_2) and so on <br />
                3. COUNT(field_1) OR COUNT(field_2) and so on <br />
                4. MAX(field_1) OR MAX(field_2) and so on <br />
                5. MIN(field_1) OR MIN(field_2) and so on <br /><br />
                You can use combinations for example: SUM(field_1) / COUNT(field_1) equivalent of AVERAGE <br /> OR SUM(field_1) / static number like 12 OR (SUM(field_1) + SUM(field_2)) / (COUNT(field_1) + COUNT(field_2))  and so on. <br />
                You must necessarily use the specified functions when applying calculations regardless of whether you use combinations! <br /> 
                
                *Operators: +(Addition), -(Subtraction), /(Division), *(Multiplication)"
              />
            </div>
            <br />
            <a-button @click="addColumnsTotals()" style="margin-top: 10px"><PlusOutlined /> Add columns totals calculation</a-button>
          </a-tab-pane>
        </a-tabs>
        <div style="margin-top: 20px">
          <a-row>
            <a-col :span="24" class="mb-3">
              <a-button @click="tokenModalVisible = true" class="mb-2">Show tokens</a-button>
              <TokensModal
                :blueprint-id="selectedBlueprint._id"
                :visible="tokenModalVisible"
                :ownerDetailsExist="template.object.view.default.convertOwnerIdToOwnerDetails"
                @close="tokenModalVisible = false"
              ></TokensModal>
              <div class="my-3">
                <h6>Embed QR Code</h6>
                <p>Click on the element below to copy the code and insert it in a template to add the QR Code:</p>
                <a-tooltip :mouseLeaveDelay="0" title="Click to copy">
                  <a-tag style="cursor: pointer" @click="copyEmbedCodeToClipboard">
                    {{ embedString }}
                  </a-tag>
                </a-tooltip>
              </div>
              <div class="mb-3">
                Html template before<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtml"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '500px' }"
                />
              </div>
              <div>
                Html template after<br />
                <Codemirror
                  v-model="localTemplate.object.view.contentHtmlAfter"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  :style="{ 'min-height': '200px', 'max-height': '400px' }"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-tab-pane>

      <a-tab-pane key="2" tab="Content HTML settings">
        <a-row v-if="template.object.view?.contentHtmlSettings">
          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.idToLabel">
              idToLabel {{ template.object.view.contentHtmlSettings.idToLabel }}
            </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.fieldReferenceData"> fieldReferenceData </a-checkbox>
          </a-col>

          <a-col :span="8">
            <a-checkbox v-model:checked="localTemplate.object.view.contentHtmlSettings.excludeFieldMultipleReferenceData">
              excludeFieldMultipleReferenceData
            </a-checkbox>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>

    <template #footer>
      <a-button key="submit" type="primary" @click="close"> Ok </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { DeleteOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons-vue';
import TokensModal from '@/core/components/TokensModal.vue';
import { TemplatesActions } from '@/apps/templateManagement/shared/templateManagement.store';
import { slugify } from '@/core/utils/string-manipulation';

export default {
  name: 'ElementSettings',
  props: ['selectedBlueprint', 'template', 'templates', 'blueprints', 'modalSetariElementVisible'],
  emits: ['update:modalSetariElementVisible'],
  components: {
    Codemirror,
    TokensModal,
    DeleteOutlined,
    PlusOutlined,
    CopyOutlined,
  },
  data() {
    return {
      modalView: this.modalSetariElementVisible,
      tokenModalVisible: false,
      localTemplate: this.template ?? null,
      extensions: null,
    };
  },
  mounted() {
    console.log(this);
    this.localTemplate = this.template ?? null;
    const extensions = [javascript(), oneDark];
    this.extensions = extensions;
  },
  watch: {
    localTemplate: {
      // update stored template.
      deep: true,
      handler(value) {
        TemplatesActions.setTemplate(value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('update:modalSetariElementVisible', false);
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    referenceBlueprintFields(type = null) {
      const referenceField = this.selectedBlueprint.fields.find(field => field._id === this.localTemplate.object.fieldId);
      const referenceBlueprint = this.blueprints.find(bp => bp._id === referenceField.structure.ruleset.blueprintId);
      return referenceBlueprint.fields.reduce((acc, field) => {
        if (type === field.structure.type || type === null) {
          acc.push({
            value: field.id,
            label: field.label,
          });
        }
        return acc;
      }, []);
    },
    selectedBlueprintFields(type = null) {
      return this.selectedBlueprint.fields.reduce((acc, field) => {
        if (type === field.structure.type || type === null) {
          acc.push({
            value: field.id,
            label: field.label,
          });
        }
        return acc;
      }, []);
    },
    slugify,
    removeColumnsTotals(row) {
      const key = this.localTemplate.object.view.default.kanban.columsTotals.indexOf(row);
      this.localTemplate.object.view.default.kanban.columsTotals = this.localTemplate.object.view.default.kanban.columsTotals.filter(
        r => this.localTemplate.object.view.default.kanban.columsTotals.indexOf(r) !== key
      );
    },
    addColumnsTotals() {
      if (!this.localTemplate.object.view.default.kanban.columsTotals) {
        this.localTemplate.object.view.default.kanban.columsTotals = [];
      }
      this.localTemplate.object.view.default.kanban.columsTotals.push({
        label: undefined,
        calculation: undefined,
        unit: undefined,
        numberFormat: undefined,
        decimalPlaces: undefined,
      });
    },
    copyField(token) {
      navigator.clipboard.writeText(token);
    },
    kanbanValues() {
      return this.localTemplate.object.view.listFields
        .filter(item => item.type === 'number' || (item?.formatting?.count ?? false) === true)
        .map(item => ({ label: item.label, value: slugify(item.label) }));
    },
  },
};
</script>
<style scoped>
.calculations {
  cursor: pointer;
}
</style>
