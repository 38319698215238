import moment from 'moment/moment';

// eslint-disable-next-line import/no-cycle
import { NewTemplatesGetters } from '@templateDataSystem/shared/newTemplateDataSystem.store';

export const FilterConditions = async query => {
  const filterConditions = [];
  await Promise.all(
    (query?.filterConditions ?? []).map(async item => {
      const useManuallyValue = item?.useManuallyValue ?? false;
      const currentLoggedInUserId = item?.currentLoggedInUserId ?? false;
      const acceptNull = item?.acceptNull ?? false;
      const acceptEmpty = item?.acceptEmpty ?? false;
      const fromCalendarSelectedDateValue = item?.fromCalendarSelectedDateValue ?? false;
      if (currentLoggedInUserId) {
        filterConditions.push({
          currentLoggedInUserId: true,
          operation: item.operation,
          comparation: item.comparation,
          includeInCreateInstance: item?.includeInCreateInstance,
          toFieldFromOtherBlueprint: item.toFieldFromOtherBlueprint,
          toReferenceFieldIds: item.toReferenceFieldIds,
        });
      } else if (fromCalendarSelectedDateValue) {
        filterConditions.push({
          operation: item.operation,
          comparation: item.comparation,
          fromCalendarSelectedDateValue: true,
          toReferenceFieldIds: item.toReferenceFieldIds,
          value: query.calendarSelectedDate,
        });
      } else if (useManuallyValue) {
        const manuallyDateDays = item?.manuallyDateDays ?? 0;
        const manuallyCurrentDate = item?.manuallyCurrentDate ?? false;
        let value = item.fromValue;
        if (acceptNull && !item.fromValue) {
          return;
        }
        if (acceptEmpty && item.fromValue === '') {
          return;
        }
        if (manuallyCurrentDate) {
          const date = moment(new Date());
          value = date.format('MM-DD-YYYY');
        }
        if (manuallyDateDays !== 0) {
          const date = moment(value);
          if (date.isValid()) {
            if (manuallyDateDays < 0) {
              value = date.subtract(Math.abs(manuallyDateDays), 'days').format('MM-DD-YYYY');
            } else {
              value = date.add(Math.abs(manuallyDateDays), 'days').format('MM-DD-YYYY');
            }
          }
        }
        filterConditions.push({
          operation: item.operation,
          comparation: item.comparation,
          includeInCreateInstance: item?.includeInCreateInstance,
          toFieldFromOtherBlueprint: item.toFieldFromOtherBlueprint,
          toReferenceFieldIds: item.toReferenceFieldIds,
          value,
        });
      } else {
        let instance;
        if (item.fromBlueprintId) {
          instance = NewTemplatesGetters.getInstanceByBlueprint(item.fromBlueprintId);
          // TODO load instance if not found
        } else {
          instance = NewTemplatesGetters.getMainInstance();
        }

        if (instance) {
          if (acceptNull && !instance[item.fromFieldId]) {
            return;
          }
          if (acceptEmpty && instance[item.fromFieldId] === '') {
            return;
          }
          filterConditions.push({
            operation: item.operation,
            comparation: item.comparation,
            includeInCreateInstance: item?.includeInCreateInstance ?? true,
            toFieldFromOtherBlueprint: item.toFieldFromOtherBlueprint,
            toReferenceFieldIds: item.toReferenceFieldIds,
            value: instance[item.fromFieldId] ?? '',
          });
        }
      }
    })
  );

  return filterConditions;
};
