<template>
  <div v-if="!viewingMode">
    <VueSignaturePad width="300px" height="150px" ref="signaturePad" :custom-style="styleOfSignaturePad" :options="{ onBegin, onEnd }" />

    <a-button @click="undo" class="mr-2">Undo</a-button>
    <a-button @click="clear">Clear</a-button>
  </div>
  <img v-else :src="rawValue" alt="Signature Pad." />
</template>

<script>
import InputMixin from './InputMixin';

const styleOfSignaturePad = {
  'background-color': 'white',
  width: '300px',
  height: '150px',
  margin: '10px 0',
  border: '1px solid lightgrey',
  'border-radius': '10px',
};

export default {
  mixins: [InputMixin],
  data() {
    return {
      styleOfSignaturePad,
    };
  },
  mounted() {
    if (this.inputValue) {
      this.$refs.signaturePad.fromDataURL(this.inputValue);
    }
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    onBegin() {
      // this.$refs.signaturePad.clearSignature();
    },
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();

      this.inputValue = data;
    },
  },
};
</script>
