import dayjs from 'dayjs';
import 'dayjs/locale/ro';

const InputMixin = {
  props: [
    'value',
    'field',
    'readOnly',
    'structureRuleset',
    'structureOptions',
    'widgetRuleset',
    'widgetOptions',
    'inputRuleset',
    'inputOptions',
    'inputSize',
    'inputPlaceholder',
    'autoFocus',
    'openDropdown',
    'currentFieldValues',
    'fields',
    'waitForValidity',
    'addNew',
    'resetFieldValueOnHide',
    'viewingMode',
    'rawValue',
    'firstLoadActive',
    'previewMode',
  ],
  inject: {
    markFieldMounted: {
      from: 'markFieldMounted',
      default: () => {
        return () => {};
      },
    },
  },
  emits: ['update', 'blur', 'update:modelValue'],
  data() {
    return {
      inputValue: null,
    };
  },
  computed: {
    isGroup() {
      return this.field?.group && this.field.group !== '';
    },
    firstFieldInGroup() {
      const group = this.field?.group;
      if (!group || group === '') {
        return null;
      }
      const findFields = (this.fields ?? []).filter(item => {
        return item.group && item.group !== '' && item.group === group;
      });
      return findFields.length ? findFields[0] : null;
    },
    isInputFilled() {
      return this.inputValue !== null && this.inputValue !== '' && this.inputValue !== undefined;
    },
  },
  mounted() {
    this.inputValue = this.getDefaultValue();
    this.handlePropValueChange();
    this.getDefaultValueFromFieldSettings();
    if (this.field?.structure.type !== 'reference' && this.field?.structure?.elementStructure?.type !== 'reference' && this.markFieldMounted) {
      this.markFieldMounted();
    }
  },
  beforeUnmount() {
    if (this.resetFieldValueOnHide) {
      this.$emit('update', null);
      this.$emit('update:modelValue', null);
    }
  },
  watch: {
    value() {
      this.handlePropValueChange();
    },
    inputValue: {
      handler() {
        this.handleInputValueWatcher();
      },
      deep: true,
    },
  },
  methods: {
    handleInputValueWatcher() {
      if (this.isInputValueValid()) {
        const transformedInputValue = this.transformInputValue(this.inputValue);
        this.emitInput(transformedInputValue);
      }
    },
    handlePropValueChange() {
      if (this.isPropValueValid()) {
        this.inputValue = this.transformPropValue(this.value);
      } else {
        this.inputValue = this.getDefaultValue();
      }
    },
    isPropValueValid() {
      return !!this.value;
    },
    isInputValueValid() {
      return true;
    },
    isInputReference() {
      return this.field?.structure?.type === 'reference';
    },
    hasFirstValueAsDefault() {
      return this.field.defaultFirstValue;
    },
    hasTemplateDefaultValue() {
      return this.field.useDefaultValueFromTemplate || false;
    },
    getTemplateDefaultValue() {
      return this.field.defaultTemplateValues;
    },
    getDefaultValue() {
      return null;
    },
    getDefaultValueFromFieldSettings() {
      if (this.addNew) {
        switch (this.field.structure.type) {
          case 'date':
            this.inputValue = this.structureOptions?.useCurrentDateAsDefault
              ? dayjs().format(this.structureOptions?.format)
              : (this.structureOptions?.defaultValue ?? null);
            break;
          default:
            if (this.hasTemplateDefaultValue()) {
              this.inputValue = this.getTemplateDefaultValue();
            } else {
              this.inputValue = this.structureOptions?.defaultValue ?? null;
            }
        }
      }
    },
    emitInput(value) {
      if (value) {
        this.$emit('update', value);
        this.$emit('update:modelValue', value);
      } else {
        this.$emit('update', this.inputValue);
        this.$emit('update:modelValue', this.inputValue);
      }
    },
    emitBlur() {
      if (this.isInputValueValid()) {
        const transformedInputValue = this.transformInputValue(this.inputValue);
        this.$emit('blur', {
          target: {
            value: transformedInputValue,
          },
        });
      }
    },
    transformPropValue(propValue) {
      return propValue;
    },
    transformInputValue(inputValue) {
      return inputValue;
    },
  },
};

export default InputMixin;
