<template>
  <a-spin v-if="loading" class="text-center d-block p-3" />
  <template v-else>
    <div class="mb-2">
      <a-select v-model:value="localEvent.pluginData.pluginId" allowClear show-search placeholder="Select Plugin" :filter-option="filterOption">
        <template v-for="plugin in plugins" :key="plugin._id">
          <a-select-option :value="plugin._id" :label="plugin.title">
            {{ plugin.title }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="selectedPlugin">
      <a-select
        v-model:value="localEvent.pluginData.endPointId"
        allowClear
        show-search
        placeholder="Select EndPoint"
        style="width: 50%"
        :filter-option="filterOption"
        @change="updateInputOutputFields"
      >
        <template v-for="endPoint in selectedPlugin.endPoints" :key="endPoint._id">
          <a-select-option :value="endPoint._id" :label="endPoint.name">
            {{ endPoint?.name !== '' ? endPoint?.name : endPoint?.path }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="localEvent.pluginData.endPointId">
      <a-select
        v-model:value="localEvent.pluginData.method"
        allowClear
        show-search
        placeholder="Select Method"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="method in methods" :key="method.id">
          <a-select-option :value="method.value" :label="method.label">
            {{ method.label }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <div class="mb-2" v-if="localEvent.pluginData.endPointId">
      <a-select
        v-model:value="localEvent.pluginData.environment"
        allowClear
        show-search
        placeholder="Select Environment"
        style="width: 50%"
        :filter-option="filterOption"
      >
        <template v-for="environment in environments" :key="environment.id">
          <a-select-option :value="environment.value" :label="environment.label">
            {{ environment.label }}
          </a-select-option>
        </template>
      </a-select>
    </div>
    <a-tabs type="card" style="margin-bottom: 20px">
      <a-tab-pane key="1" tab="Header params">
        <div class="row mb-3" v-for="(body, headerIndex) in localEvent.pluginData.headerParams" :key="'body_' + headerIndex">
          <div class="col-4">
            <a-input v-model:value="body.key" placeholder="Key" />
          </div>
          <div class="col-5">
            <a-input v-model:value="body.value" placeholder="Value" />
          </div>
          <div class="col-2" v-if="body.authRestId">
            <a-tooltip
              v-if="!!body.authRestId"
              :mouseLeaveDelay="0"
              title="User Authentication group if available (it will select the auth token from a group of authorizations based on the group and subtenantId)."
            >
              <a-checkbox style="position: relative; top: 4px" v-model:checked="body.useAuthGroup">Use Auth group</a-checkbox>
            </a-tooltip>
          </div>
          <div class="col-2" v-else></div>
          <div class="col-1">
            <a-button @click="removeHeaderParam(body)"><DeleteOutlined /></a-button>
          </div>
        </div>

        <a-button @click="addHeaderParam()" style="margin: 0px 5px 5px 0px"><PlusOutlined /> Add param</a-button>
        <a-button @click="showAuthTokenModal = true" style="margin: 0px 5px 5px 0px"><PlusOutlined /> Add authentication token</a-button>
        <a-button size="small" @click="openTokens = true" type="primary" style="float: right">Tokens to use</a-button>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Blueprint params">
        <div
          v-for="(inputField, key) in Object.entries(selectedEndPoint?.inputFields ?? {}).map(([key, type]) => ({ key, type }))"
          :key="inputField.key"
        >
          <div class="row">
            <div class="col-6">
              <div style="padding-top: 4px; padding-left: 10px">
                <b>{{ key + 1 }}. Input field - {{ inputField.key }} ({{ inputField.type }})</b>
              </div>
            </div>
            <div class="col-6">
              <ReferenceFields
                :value="localEvent.pluginData?.inputParams?.[inputField.key] ?? undefined"
                :key="referenceFieldsKey"
                :blueprints="allBlueprints"
                :selectedBlueprint="mainBlueprint"
                :toFieldFromOtherBlueprint="false"
                :forceShowId="true"
                @toField="(value, index, mountedFinish) => selectField(value, index, mountedFinish, inputField)"
              />
            </div>
          </div>
          <hr />
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Custom params">
        <div class="row mb-3" v-for="(body, customParamIndex) in localEvent.pluginData.customParams" :key="'body_' + customParamIndex">
          <div class="col-4">
            <a-input v-model:value="body.key" placeholder="Key" />
          </div>
          <div class="col-7">
            <a-input v-model:value="body.value" placeholder="Value" />
          </div>
          <div class="col-1">
            <a-button @click="removeCustomParam(body)"><DeleteOutlined /></a-button>
          </div>
        </div>

        <a-button @click="addCustomParam()"><PlusOutlined /> Add param</a-button>
        <a-button size="small" @click="openTokens = true" type="primary" style="float: right">Tokens to use</a-button>
      </a-tab-pane>
    </a-tabs>
    <div v-if="selectedEndPoint?.expectedFields">
      Expected fields to return:
      <Codemirror
        disabled
        :model-value="codemirrorDataToStringify"
        :options="{
          btabSize: 4,
          mode: 'text/html',
          theme: 'base16-dark',
          lineNumbers: true,
          line: true,
        }"
        :extensions="extensions"
      />
    </div>

    <a-modal v-model:open="showAuthTokenModal" title="Add authentication token" width="720px" :footer="false">
      <AuthTokens @tokenSelected="addAuthToken" />
    </a-modal>
    <TokensModal :visible="openTokens" @close="openTokens = false" :blueprintId="selectedBlueprintId" />
  </template>
</template>

<script>
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { blueprintApi } from '@/apps/dataSystem/api';
import { pluginsManagementApi } from '@/apps/pluginsManagement/api';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue';
import TokensModal from '@core/components/TokensModal';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFields.vue';
import AuthTokens from '@/apps/dataProvider/views/dataProviderBuilder/components/forms/subscription/components/authTokens.vue';

export default {
  name: 'workflowBuilderPluginData',
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList', 'eventIndex', 'prevEvent'],
  components: {
    Codemirror,
    DeleteOutlined,
    PlusOutlined,
    ReferenceFields,
    AuthTokens,
    TokensModal,
  },
  data() {
    return {
      loading: false,
      extensions: null,
      roles: [],
      subtenants: [],
      localEvent: { ...this.event },
      allBlueprints: [],
      mainBlueprint: null,
      mainBlueprintFields: [],
      plugins: [],
      methods: [
        { id: 1, label: 'GET', value: 'GET' },
        { id: 2, label: 'POST', value: 'POST' },
      ],
      environments: [
        { id: 1, label: 'DEVELOPMENT', value: 'DEVELOPMENT' },
        { id: 2, label: 'PRODUCTION', value: 'PRODUCTION' },
      ],
      headerParams: [],
      customParams: [],
      toFieldFromOtherBlueprint: false,
      toField: [],
      toReferenceFieldIds: [],
      referenceFieldsKey: 0,
      showAuthTokenModal: false,
      openTokens: false,
    };
  },
  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
  },
  computed: {
    selectedPlugin() {
      if (this.plugins) {
        return this.plugins.find(plugin => plugin._id === this.localEvent.pluginData.pluginId);
      }
      return null;
    },
    selectedEndPoint() {
      if (this.selectedPlugin?.endPoints) {
        return this.selectedPlugin.endPoints.find(plugin => plugin._id === this.localEvent.pluginData.endPointId);
      }
      return null;
    },
    codemirrorDataToStringify() {
      return JSON.stringify(this.selectedEndPoint?.expectedFields ?? {}, null, 2);
    },
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
  },
  async created() {
    this.loading = true;
    this.extensions = [javascript(), oneDark];
    await this.fetchPlugins();
    await this.fetchBlueprint();
    this.loading = false;
  },
  methods: {
    updateInputOutputFields() {
      this.localEvent.pluginData.inputFields = this.selectedEndPoint?.inputFields;
      this.localEvent.pluginData.expectedFields = this.selectedEndPoint?.expectedFields;
    },
    async fetchPlugins() {
      this.plugins = await pluginsManagementApi.getPluginsByApp();
    },
    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const { blueprint, fieldsById } = await blueprintApi.getOne(this.selectedBlueprintId);

      await this.fetchAllBlueprint();

      this.mainBlueprint = blueprint;
      this.mainBlueprintFields = Object.values(fieldsById);
    },
    async fetchAllBlueprint() {
      this.allBlueprints = await blueprintApi.getAllWithFields();
    },
    filterOption(input, option) {
      return option.children()[0].children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0;
    },
    selectField(value, index, mountedFinish, inputField) {
      if (mountedFinish) {
        if (!this.localEvent.pluginData.inputParams) {
          this.localEvent.pluginData.inputParams = {};
        }
        let currentValues = this.localEvent.pluginData.inputParams[inputField.key] ? [...this.localEvent.pluginData.inputParams[inputField.key]] : [];
        if (!Object.keys(value ?? '').length || (index <= currentValues.length && Object.keys(value ?? '').length > 0)) {
          currentValues.forEach((_, i) => {
            if (i >= index) {
              currentValues[i] = undefined;
            }
          });
          currentValues = currentValues.filter(item => item !== undefined);
        }
        currentValues[index] = value;
        this.localEvent.pluginData.inputParams = {
          ...this.localEvent.pluginData.inputParams,
          [inputField.key]: currentValues,
        };
        this.referenceFieldsKey += 1;
      }
    },
    removeCustomParam(row) {
      const key = this.localEvent.pluginData.customParams.indexOf(row);
      this.localEvent.pluginData.customParams = this.localEvent.pluginData.customParams.filter(
        r => this.localEvent.pluginData.customParams.indexOf(r) !== key
      );
    },
    addCustomParam() {
      this.localEvent.pluginData.customParams.push({
        key: undefined,
        value: undefined,
      });
    },
    removeHeaderParam(row) {
      const key = this.localEvent.pluginData.headerParams.indexOf(row);
      this.localEvent.pluginData.headerParams = this.localEvent.pluginData.headerParams.filter(
        r => this.localEvent.pluginData.headerParams.indexOf(r) !== key
      );
    },
    addHeaderParam() {
      this.localEvent.pluginData.headerParams.push({
        key: undefined,
        value: undefined,
      });
    },
    addAuthToken(payload) {
      this.localEvent.pluginData.headerParams.push({
        key: payload.key,
        value: '{{=token}}',
        authRestKey: payload.key,
        authRestResponseType: payload.responseType,
        authRestId: payload.authRestId,
        useAuthGroup: false,
      });

      this.showAuthTokenModal = false;
    },
  },
};
</script>
<style scoped>
.blueprint-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.blueprint-fields:last-child {
  margin-bottom: 0px;
}
.blueprint-fields .cell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.mutted {
  font-size: 12px;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 70%;
  padding-left: 10px;
}
</style>
