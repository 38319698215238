<template>
  <div class="text-center pt-2 pb-2" v-if="loading || !pluginInputData">
    <a-spin />
  </div>
  <div v-else class="padd-20">
    <a-card>
      <h4>{{ localPlugin?.title }}</h4>
      <a-tag color="cyan">Version: {{ localPlugin?.version }}</a-tag>
      <div class="mt-2">
        <p v-html="localPlugin?.description"></p>
        <template v-for="(dataGroup, groupIndex) in pluginInputData" v-bind:key="'_dataGroup_' + groupIndex">
          <div class="row padd-10">
            <div class="col-2">
              <span style="min-width: 125px; margin-right: 15px">
                {{ dataGroup?.title }}
                <a-tooltip>
                  <template #title> {{ dataGroup?.hint }}</template>
                  <InfoCircleOutlined />
                </a-tooltip>
              </span>
              <BlueprintSelect v-model="dataGroup.blueprintId" placeholder="Select a blueprint..." style="width: 100%" />
            </div>
            <div class="col-10 row">
              <div v-for="(field, fieldIndex) in dataGroup?.fields" v-bind:key="groupIndex + '_field_' + fieldIndex" class="col-3">
                <template v-if="dataGroup?.blueprintId">
                  <span>{{ field?.label }} ({{ field?.fieldType }})</span>
                  <br />
                  <a-select
                    placeholder="Select field"
                    style="min-width: 100%"
                    v-model:value="field.fieldId"
                    :options="getBlueprintFields(dataGroup.blueprintId)"
                    :filter-option="filterOption"
                    show-search
                  >
                  </a-select>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-if="pluginEndpoints">
          <span>EndPoints:</span>
          <template v-for="point in pluginEndpoints" v-bind:key="point.path">
            <a-card>
              <a-tag :color="point.method === 'POST' ? '#108ee9' : '#87d068'">{{ point.method ?? 'Not set' }}</a-tag> {{ point.path }} ->
              {{ point.description }}<br /><br />
              <span v-if="point?.headerParams">Header Params</span>
              <pre v-if="point?.headerParams">{{ point?.headerParams }}</pre>
              <span v-if="point?.customParams">Custom params</span>
              <pre v-if="point?.customParams">{{ point?.customParams }}</pre>
              <span>Input Fields</span><br />
              <pre>{{ point.inputFields }}</pre>
              <span>Expected Fields</span><br />
              <pre>{{ point.expectedFields }}</pre>
            </a-card>
          </template>
          <div style="text-align: center">
            <a-button type="primary" :loading="savePluginLoading" style="margin-right: 10px" @click="save">{{ saveButtonLabel }}</a-button>
          </div>
        </template>
      </div>
    </a-card>
  </div>
</template>

<script>
import { BlueprintSelect } from '@/apps/dataSystem/components/BlueprintSelect';
import { blueprintApi } from '@/apps/dataSystem/api';
import { InfoCircleOutlined } from '@ant-design/icons-vue';
import { PluginsGetters, PluginsActions } from '../../shared/pluginsManagement.store';

export default {
  name: 'PluginsList',
  components: { BlueprintSelect, InfoCircleOutlined },
  data() {
    return {
      loading: false,
      pluginPath: this.$route.params.pluginPath ?? null,
      pluginInputData: [],
      blueprintList: [],
      isActive: true,

      savePluginLoading: false,
      plugin: null,
    };
  },
  async mounted() {
    this.loading = true;
    await this.init();
    this.loading = false;
  },
  computed: {
    selectedBlueprint() {
      return this.blueprintList.find(b => b._id === this.input.blueprintId);
    },
    pluginStructure: PluginsGetters.getStructure,
    pluginEndpoints() {
      return this.plugin?.endPoints ?? this.pluginStructure?.endPoints ?? [];
    },
    localPlugin() {
      return this.plugin ?? this.pluginStructure;
    },
    saveButtonLabel() {
      let update = 'INSTALL PLUGIN';
      if (this.plugin?._id) {
        update = 'SAVE PLUGIN';
        if (this.plugin.version !== this.pluginStructure.version) {
          update = 'UPDATE PLUGIN';
        }
      }
      return update;
    },
  },
  methods: {
    async init() {
      this.blueprintList = await blueprintApi.getAllWithFields();
      await PluginsActions.fetchPlugin(this.pluginPath);
      this.plugin = PluginsGetters.getPlugin();
      if (!this.plugin) {
        this.pluginStructureToInputData(this.pluginStructure);
      } else {
        this.pluginInputData = this.plugin?.data;
      }
    },
    getBlueprintFields(blueprintId) {
      const selectedBlueprint = this.blueprintList.find(b => b._id === blueprintId);
      let fields = [];
      fields = selectedBlueprint.fields.map(field => {
        return {
          value: field._id,
          label: field.label,
        };
      });
      fields.push({
        value: '_id',
        label: 'ID',
      });
      return fields;
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    pluginStructureToInputData(pluginStructure) {
      pluginStructure.data.forEach(dataGroup => {
        const fieldsData = [];
        dataGroup.fields.forEach(dataGroupField => {
          fieldsData.push({
            fieldId: null,
            key: dataGroupField.key,
            label: dataGroupField.label,
            fieldType: dataGroupField.fieldType,
          });
        });
        this.pluginInputData.push({
          blueprintId: null,
          key: dataGroup.key,
          title: dataGroup.title,
          hint: dataGroup.hint,
          fields: fieldsData,
        });
      });
    },
    async save() {
      this.savePluginLoading = true;
      let endPoints = this.pluginStructure?.endPoints;
      if ((this.plugin?.endPoints ?? []).length) {
        endPoints = (this.pluginStructure?.endPoints ?? []).map((item, index) => {
          return {
            ...item,
            _id: this.plugin.endPoints[index] ?? undefined,
          };
        });
      }
      let dataParams = this.pluginInputData;
      if ((this.plugin?.data ?? []).length) {
        dataParams = (this.pluginInputData ?? []).map((item, index) => {
          return {
            ...item,
            _id: this.plugin?.data[index] ?? undefined,
          };
        });
      }
      const data = {
        ...(this.plugin ?? {}),
        title: this.pluginStructure?.title,
        path: this.pluginStructure?.path,
        description: this.pluginStructure?.description,
        version: this.pluginStructure?.version,
        output: this.pluginStructure?.output ?? null,
        endPoints,
        data: dataParams,
        isActive: this.isActive,
      };
      if (!this.plugin) {
        this.plugin = data;
      }
      await PluginsActions.savePlugin(this.pluginPath, data);
      this.plugin = PluginsGetters.getPlugin();
      this.savePluginLoading = false;
    },
  },
};
</script>
